<template>
    <div class="refund"  v-if="$store.state.userDate">
        <Head :type="0"> 
            <template #title>
                <div >申请退款</div>
            </template>
        </Head>
      
<div class="main">
<div class="tips">
    <div class="title">
        温馨提示：
    </div>
    <div class="content">
        
        <p>订单一旦取消，无法恢复</p>
        <!-- <p>2、如遇订单拆分，京券将换成同价值京豆返还</p>
        <p>3、支付券不予返还；支付优惠一 并取消 </p>
        <p>4、订单一旦取消，无法恢复</p>
        <p>5、微信搜一搜订单返现金额会从退款中扣除</p> -->
    </div>
</div>
<ul>
    <li v-if="$route.query.type!=1">
        <div class="title">
            退款原因
        </div>
        <div class="content" @click="show=true" style="color:#999;">{{reason.name}}</div>
        <img src="../../../assets/img/right_gery.png" alt="">
    </li>
    <li >
        <div class="title">联系人</div>
        <div class="content">{{$store.state.userDate.nickName}}</div>
    </li>
     <li>
        <div class="title">联系方式</div>
        <div class="content">{{$store.state.userDate.phone}}</div>
    </li>
    <li v-if="$route.query.type==1">
        <div class="title">物流公司</div>

        <div class="content">  <input type="text" name="" id="" v-model="info"  @input="query"> </div>
<!-- {{express}} -->
       
         <img src="../../../assets/img/right_gery.png" alt="">
    </li>
     <li v-if="$route.query.type==1">
        <div class="title">快递单号</div>
        <div class="content"> <input type="text" name="" id="" v-model="expressNo" placeholder="请输入快递单号" > </div>
    </li>
    <li v-if="$route.query.type==1">
        <div class="title" style="">上传图片</div>
        <div class="content">
            <van-uploader v-model="fileList" multiple :max-count="5" :after-read="afterRead"  :accept="'image/png'"/>

        </div>
        
    </li>
</ul>
</div>
<div class="footer" @touchmove.prevent>
    <div  class="btn"  @click="tuikuan()"> 申请退款</div>
</div>

<van-action-sheet v-model="show"  >
  <div class="box">
      <div class="title">请选择取消订单的原因（必选）</div>
      <div class="choose">
          <div class="choose-item" v-for="item in chooseList" :key="item.name" @click="reason=item,show=false">
              <img src="../../../assets/roomImg/choose.png" v-if="item.name!=reason.name" alt="">
              <img src="../../../assets/chooseRead.png" v-if="item.name==reason.name" alt="">

              <div>{{item.name}}</div>
          </div>
      </div>
      <div class="bottom" @click="show=false">
          确定
      </div>
  </div>
</van-action-sheet>
<van-popup v-model="showPicker" round position="bottom">
  <van-picker
    show-toolbar
    :columns="list"
    @cancel="showPicker = false"
    @confirm="onConfirm"
    value-key="companyName"
    
  />
</van-popup>

    </div>
</template>
<script>

import { getReason,postRefund,upLoad,getVoucher,getLogisticsList} from "../../../api/maker/order.js"
import Head from '../../../components/head/index.vue'
    export default {
       
        data(){
            return{
                show:false,
                reason:{name:"请选择退款原因"},
                chooseList:null,
                logistics:null,
                fileList: [],
                showPicker: false,
               list:[],
               express:'请选择快递公司',
               expressNo:'',
               info:'',
               timerID:0
                
            }
        },
        components:{Head},
        created(){ 
           
           getReason({id:this.$route.query.id}).then(res=>{ 
               if(res.data.code===0){ 
                   this.chooseList=res.data.data
               }
           })

        //    getLogisticsList().then(res=>{ 
        //        if(res.data.code===0){ 
        //            //
        //            this.list=res.data.data
                   
        //        }
        //    })
        },
        beforecreated(){
            if(!this.$route.query.refundId){
                this.$router.back()
            }
        },
            mounted(){
               this.getUser()
               console.log(this.$store.state);
                    this.setRem()
                    window.addEventListener('resize',this.setRem)
                    window.addEventListener("orientationchange", this.setRem);
                    
            },
            methods:{
                      query(){ 
                          clearInterval(this.timerID)

                         this.timerID= setTimeout(() => {
                               getLogisticsList({name:this.info}).then(res=>{ 
                                if(res.data.code===0){ 
                                    this.list=res.data.data
                                    if(res.data.data.length!==0){ 
                                    this.showPicker=true

                                    }
                                }
                            })
                          }, 500);
                           
                      },
                    tuikuan(){

                        //  reszon:"请选择退款原因",
                         if(this.reason.name==="请选择退款原因"&&this.$route.query.type!=1){

                             this.$toast('退款原因不能为空')
                            
                              return

                         }
                           


                           let arrs = []
                           this.fileList.forEach((item)=>{ 
                               arrs.push(item.url)
                           })

                        console.log(this.reason)
                        
                        postRefund({orderId:this.$route.query.id,
                        
                                refundReson:this.reason.id ,
                                 description:this.reason.name,
                                vouchers:arrs,
                                refundResonStr:this.reason.name
                        }).then(res=>{ 

                            if(res.data.code===0){ 
                                this.$toast.success('申请成功')
                                setTimeout(()=>{ 
                                    this.$router.back()
                                },200)
                            }else{ 
                                this.$toast.fail('申请失败')

                            }
                        })
                            // let re = {refundReson:this.reszon ,refundTradeNo:Date.now(),status:'0'} //退款原因，退款时间戳
                              
                                
                            // let data = { ...this.$route.query ,...re}   
                            //  sessionStorage.setItem('status',JSON.stringify({ index:String(this.$route.query.index) ,attribute:this.$route.query.attribute,status:1}))
                    //         refund(data).then(res=>{

                    //        if(res.data.code===0){

                    //            if(res.data){
                    //                this.$toast.success('申请成功')


                    //                this.$router.back()

                    //            }else{
                    //                this.$toast.fail('申请失败')
                    //            }
                               
                    //        }
                    //    })     

                    },
                    afterRead(file) {
                file.status = 'uploading';
                // file.message = '上传中...';
                
                console.log(this.fileList)
                let formData = new window.FormData();
                formData.append("file", file.file);
                formData.append("fileType", "image/png");
                formData.append("dir", "material");
                upLoad(formData).then(res=>{ 
            if(res.data.code===0){ 

                getVoucher({url:res.data.data}).then(res=>{ 
                file.status = 'upload';

                file.url=res.data.data.imageDomain+'/'+res.data.data.imageRelativeUrl

                }).catch(()=>{ 

                file.status = 'failed';

                })

            }
            console.log(this.fileList)
        }).catch(()=>{ 
                file.status = 'failed';

        })
    },
  onConfirm(value){ 

        this.showPicker = false;
        this.express=value
        this.info=this.express.companyName
  }
                   
            },

           
    }
    
</script>
<style lang="less" scoped>
.refund{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    font-family: PingFangSC-Medium, PingFang SC;
    display: flex;
    flex-direction: column;
    z-index: 120;
    background-color: white;
  
   
     .main{

        top: 1.333333rem;
        width: 100%;
      overflow: auto;
      flex: 1;
    
        .tips{
            margin: 0 .453333rem;
            margin-top: .4rem;
            height: 5.066667rem;
            background: #F8F8F8;
            border-radius: .133333rem;
            padding: .48rem .4rem;
            box-sizing: border-box;
            .title{
                font-size:.426667rem;
                font-family: PingFangSC-Medium;
                color: rgba(0, 0, 0, 1);
                text-overflow: ellipsis;
                margin-bottom: .533333rem;
                font-weight: 600;
            }
            .content{
                white-space: pre-wrap;
                font-size: .373333rem;
                font-family: PingFangSC-Regular;
                color: rgba(0, 0, 0, 1);
                text-overflow: ellipsis;
            }
        }
        ul{
           padding-top: .4rem;
           padding-bottom: constant(safe-area-inset-bottom);
           padding-bottom: env(safe-area-inset-bottom);

        li{
            display: flex;
            border-bottom: 1px solid #eee;
            font-size: .453333rem;
            padding: .4rem ;
            align-items: center;
            .title{
                width: 2.08rem;
                height: .8rem;
                border-right:1px solid rgba(0,0,0,.1) ;
                padding:0 .4rem;
                 display: flex;
                align-items: center;
                justify-content: center;
                
            }

            .content{
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: .266667rem;
              /deep/  .van-image{ 
                    width: 1.066667rem !important;
                    height: 1.066667rem !important;
                    border-radius: 10%;
                }
             /deep/  .van-uploader__upload{ 
                     width: 1.066667rem !important;
                    height: 1.066667rem !important;
                    border-radius: 10%;

                }
            }
            img{
                width: 25px;
                height: 24px;
            }
            }
        }

    }
    .footer{
        width: 100%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
        padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
        display: flex;
        align-items: center;
            .btn{
        width: 9.173333rem; 
        height: .96rem;
        background-image: linear-gradient(to right , #FF7200,#FF3C00);
        border-radius: .48rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: .373333rem;
        margin: .266667rem auto;
    }
    }
    .box{
        padding-top:.533333rem ;
         padding-bottom: constant(safe-area-inset-bottom);///兼容 IOS<11.2/
            padding-bottom: env(safe-area-inset-bottom);///兼容 IOS>11.2/
        .title{
            text-align: center;
            height: .586667rem;
            font-size: .426667rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            padding-bottom: .533333rem;
        }
        .choose-item{
            display: flex;
            align-items: center;
            height: 1.493333rem;
            border-bottom: .013333rem solid #eee;
            padding-left: .453333rem;
            img{
                width: .7rem;
                height: .64rem;
                margin-right: .4rem;
            }
            
        }
        .bottom{
            width: 9.173333rem;
            height: .96rem;
            background-image: linear-gradient(to right,#FF7200,#FF3C00);
            border-radius: .48rem;
            margin:.266667rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .373333rem;
            color: white;
        }
    }
    

   
}

</style>